import MajorLayout from './components/major'
import TupuHeader from './components/header'
import TOperations from './components/operations'
import TupuPopup from './components/popup'
import highlightCode from './components/highlight-code'



//Overwrite the tag filter function to support case-insensitive filtering and description filtering
function opsFilter(taggedOps, phrase) {
    const _phrase = phrase.toLowerCase()
    return taggedOps.filter((tagObj, tag) => {
        const _tag = tag.toLowerCase()
        if (_tag.indexOf(_phrase) >= 0 ) return true

        const desc = tagObj.getIn(["tagDetails", "description"], null) || ''
        return desc.toLowerCase().indexOf(_phrase) !== -1
    })
}


const PathSubscribers = {}

function subscribePath(path, cb) {
    const subrs = PathSubscribers[path] = PathSubscribers[path] || []
    subrs.push(cb)
}

function pathResponseChanged(value, path) {
    const subrs = PathSubscribers[path]
    if (!subrs) return
    subrs.forEach(cb => cb(value))
}



function filter() {
    return {
        fn: {
            opsFilter,
            subscribePath,
            pathResponseChanged,
        }
    }
}


export default function() {
    const majorPlugins = {
        components: {
            MajorLayout,
            TupuHeader,
            TOperations,
            TupuPopup,
            highlightCode,
        },
    }

    return [
        majorPlugins,
        filter,
    ]
}
