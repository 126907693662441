import React, { Component } from "react"
import PropTypes from "prop-types"

import './header.scss'


function parseAuth(value) {
    const typ = typeof value
    if (typ === 'object') {
        return { token: value.token, expiresIn: value.expiresIn, }
    }
    if (typ === 'string') {
        try {
            return JSON.parse(value)
        }
        catch (e) {
            return {}
        }
    }
    return {}
}


function formatExpires(expires) {
    const d = typeof expires === 'string' ? new Date(expires) : expires
    return `${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
}


export default class Header extends Component {
    static propTypes = {
        title: PropTypes.string,
        version: PropTypes.string,
        getComponent: PropTypes.func.isRequired,
        overTop: PropTypes.bool,
    }

    constructor(props, context) {
        super(props, context)

        const configs = props.getConfigs()
        const entries = configs.entries || []
        const url = props.specSelectors.url()
        let selectedIndex = 0

        entries.forEach((entry, i) => {
            if(url === entry.url) {
                selectedIndex = i
            }
        })

        this.tokenRef = React.createRef()

        this.state = { url, selectedIndex, auth: {}, authCopied: false, }

        const fn = props.fn
        if (fn && fn.subscribePath) {
            fn.subscribePath(configs.authPath, (value) => {
                const auth = parseAuth(value)
                if (auth.token && this.state.auth.token !== auth.token) {
                    //console.log('Token updated: |%s|', auth.token)
                    this.setState({
                        auth: {
                            token: auth.token,
                            expiresAt: auth.expiresAt ? auth.expiresAt : null,
                        }
                    })
                }
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ url: nextProps.specSelectors.url() })
    }

    // componentDidMount() {
    //     const configs = this.props.getConfigs()
    //     const entries = configs.entries || []
    //
    //     if (entries.length > 0) {
    //         let targetIndex = this.state.selectedIndex
    //         let primaryName = configs["urls.primaryName"]
    //         if (primaryName) {
    //             entries.forEach((spec, i) => {
    //                 if(spec.name === primaryName) {
    //                     this.setState({ selectedIndex: i })
    //                     targetIndex = i
    //                 }
    //             })
    //         }
    //
    //         const url = entries[targetIndex].url
    //         if (url !== this.state.url) {
    //             this.loadSpec(url)
    //         }
    //     }
    // }

    render() {
        const { title, version, getComponent, getConfigs, overTop, } = this.props

        const VersionStamp = getComponent('VersionStamp')

        const configs = getConfigs()
        const entries = configs.entries || []

        const auth = this.state.auth
        let expireTxt = ''
        if (auth.token && auth.expiresAt) {
            const d = new Date(auth.expiresAt)
            expireTxt = (d.getTime() - Date.now()) > 0 ? ('expires at '+formatExpires(d)) : 'expired'
        }

        return (
            <div className={["header-bar", overTop ? 'over-top' : '' ].join(' ').trim()}>
                <div>
                    <img className="logo" src={`images/header-logo${configs.resSuffix}.svg`} alt="TUPU Tech" />
                    <div className="info">
                        <h2 className="title" >
                            <span>{ title }</span>
                            { version && <VersionStamp version={version} /> }
                        </h2>
                    </div>
                </div>
                <ul>
                    {
                        entries.map((entry,i) => {
                            const active = entry.url === this.state.url
                            return (
                                <li key={'nav-'+i} className={active ? 'active' : ''} onClick={ (e) => this.clickOnUrl(active ? '' : entry.url) }>
                                    <span>{entry.text || entry.name}</span>
                                </li>
                            )

                        })
                    }
                </ul>
                <div className="auth-bar">
                    {
                        auth.token && (
                            <div className="auth-record" onClick={e => this.clickOnTokenBar(e)}>
                                <div>
                                    <h3>Token<span>{expireTxt}</span><span className="hints">{this.state.authCopied ? 'Copied' : ''}</span></h3>
                                    <div className="token">{auth.token}</div>
                                    <input ref={this.tokenRef} type="text" value={auth.token} />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }


    loadSpec(url) {
        const { specActions } = this.props
        specActions.updateUrl(url)
        specActions.download(url)
    }

    clickOnUrl(url) {
        if (!url || this.state === url) return

        const configs = this.props.getConfigs()
        const entries = configs.entries || []

        if (entries.length > 0) {
            entries.forEach((entry,i) => {
                if (entry.url === url) {
                    this.setState({ selectedIndex: i, url, })
                    this.loadSpec(url)
                }
            })
        }
    }

    clickOnTokenBar(e) {
        const input = this.tokenRef.current
        input.select()
        if (document.execCommand("copy")) {
            this.setState({ authCopied: true })
            setTimeout(() => {
                this.setState({ authCopied: false })
            }, 5000)
        }
    }
}
