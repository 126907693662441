import DocReady from 'es6-docready'

import * as serviceWorker from './serviceWorker'

import SwaggerUI from '@tuputech/swagger-ui'

import '@tuputech/swagger-ui/dist/swagger-ui.css'
import './index.scss'

import MajorPreset from './preset'


const DefMaxOrder = 1000


let SUISystem = null
//const spec = system.getSystem().specSelectors.specJson()
let TagDefs = null

function initTagDefs(disabled = false) {
    if (disabled === true) return {}

    if (!TagDefs) {
        TagDefs = {}

        const specMap = SUISystem && SUISystem.spec && SUISystem.spec()
        if (specMap) {
            const jsonMap = specMap.get('json')
            if (jsonMap) {
                const tagList = jsonMap.get('tags')
                tagList.forEach((obj,k) => {
                    if (obj && obj.get) {
                        const name = obj.get('name'),
                            order = obj.get('x-order')
                        if (name && typeof order === 'number') {
                            TagDefs[name] = order
                        }
                    }
                })
            }
        }
    }

    return TagDefs
}

function getOrderFromTag(tag) {
    let order
    if (typeof tag === 'string') {
        order = initTagDefs(true)[tag]
    }
    //modified in swagger-ui to pass tagObj for comparison
    else if (tag && tag.get) {
        order = tag.get('x-order')
    }
    return typeof order === 'number' ? order : DefMaxOrder
}


const TagsSorters = {
    name: 'alpha',
    alpha: 'alpha',

    // Sort operations by ordering defined in tags configuration
    ordered: function(tag1, tag2) {
        return getOrderFromTag(tag1) - getOrderFromTag(tag2)
    },
}

const OperationsSorters = {
    path: 'alpha',
    method: 'method',

    // Sort operations by ordering defined in route schema sequence
    ordered: function(map1, map2) {
        const op1 = map1.get('operation'),
            op2 = map2.get('operation')
        if (!op1 || !op2) return

        const order1 = op1.get('x-order') || DefMaxOrder,
            order2 = op2.get('x-order') || DefMaxOrder
        //console.log('--- com-op: ', order1, '|--|', order2)

        // console.log('--------------')
        // map1.get('operation').forEach((v,k) => {
        //     console.log('K: %s | ', k, v)
        // })
        //console.log('-------------- order diff: ', order1 - order2)

        return order1 - order2
    },
}



function chromeVersion() {
    const ua = window.navigator.userAgent || '',
        re = /Chrome\/(\d+)/i,
        arr = re.exec(ua),
        ver = arr && arr.length >=2 ? parseInt(arr[1],10) : 0
    //console.log('--- UA: ', ua, '|', ver)
    return ver
}



function parseCM(CM) {
    if (!CM) {
        throw new Error('Missing context model')
    }

    // const entries = [
    //     {
    //         name: 'swagger1',
    //         text: '常用接口',
    //         url: 'http://localhost:55302/personnel/swagger.json',
    //     },
    //     // {
    //     //     name: 'swagger2',
    //     //     text: '人员管理',
    //     //     url: 'http://localhost:9200/api/swagger-2.json',
    //     // },
    // ]
    // Object.keys(CM).forEach(k => {
    //     const v = CM[k]
    //     if (!v || v.indexOf('hapiSwagger') > 0) {
    //         switch (k) {
    //             case 'url':
    //                 CM[k] = entries[0].url //"http://localhost:3500/apidoc/swagger.json"
    //                 break
    //             case 'entries':
    //                 CM[k] = entries
    //                 break
    //             // case 'lang':
    //             //     CM[k] = "zh-cn"
    //             //     break
    //             default:
    //                 CM[k] = ''
    //         }
    //     }
    // })
    return CM
}

const CM = parseCM(window.CM)

if (CM.lang) {
    const node = document.createElement("script")
    node.src = `${CM.basePath || ""}/lang/${CM.lang}.js`
    const s = document.getElementsByTagName("script")[0]
    s.parentNode.insertBefore(node, s)
}


DocReady(function() {
    function getLangText(key, txt) {
        const i18n = window.SwaggerI18n || {}
        return i18n[key] || txt || key
    }

    SUISystem = SwaggerUI({
        domNode: document.getElementById('root'),
        url: CM.url,
        //urls: CM.urls || null,
        entries: CM.entries || null,

        //Equivalent to set options 'sortTags' as 'name' in HAPI-Swagger
        tagsSorter: TagsSorters[ CM.tagSort || "ordered" ],
        //Equivalent to set options 'sortEndpoints' as 'ordered' in HAPI-Swagger
        operationsSorter: OperationsSorters[ CM.operationSort || "ordered" ],
        //Invisible models list
        defaultModelsExpandDepth: -1,
        deepLinking: true,
        //show duration
        displayRequestDuration: true,
        //show filter block
        filter: true,

        //tupu-specified parameters
        tryItOutEnabled: true,
        tupuLayout: true,
        resSuffix: CM.resSuffix || '',
        authPath: CM.authPath,
        getLangText,
        disablePreventDefaultOnScroll: chromeVersion() >= 56,

        //for customized
        presets: [
            SwaggerUI.presets.apis,
            MajorPreset,
        ],
        layout: "MajorLayout",
    })
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
