import React, { Component } from "react"
import PropTypes from "prop-types"

import './major.scss'


function getWinDomain() {
    let domain = window.location.protocol || ''
    if (domain.substr(-1) !== ':') {
        domain += ':'
    }
    domain += '//' + window.location.host
    const port = window.location.port ? (':'+window.location.port) : ''
    if (domain.substr(0 - port.length) !== port) {
        domain += port
    }
    return domain
}



export default class Major extends Component {
    static propTypes = {
        errSelectors: PropTypes.object.isRequired,
        errActions: PropTypes.object.isRequired,
        specSelectors: PropTypes.object.isRequired,
        oas3Selectors: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
    }


    constructor(props) {
        super(props)

        this.lastScrollY = 0
        this.ticking = false

        this.state = {
            overTop: false,
            popupUrl: '',
            popupShow: false,
        }

        this.domain = getWinDomain()
    }


    _onScroll() {
        this.lastScrollY = window.scrollY

        if (!this.ticking) {
            window.requestAnimationFrame(() => {
                const overTop = this.state.overTop
                //console.log('-- main scrollTop: ', this.lastScrollY)
                if (overTop && this.lastScrollY < 12) {
                    this.setState({ overTop: false })
                }
                else if (!overTop && this.lastScrollY > 25) {
                    this.setState({ overTop: true })
                }

                this.ticking = false
            })
            this.ticking = true
        }
    }

    onScroll = () => this._onScroll()


    interceptLinkClick(elem) {
        const href = elem.href || ''
        if (elem.nodeName === 'A') {
            if (href.length < this.domain.length) return false

            const domain = elem.href.substr(0, this.domain.length)
            const sameDomain = domain === this.domain

            const rest = elem.href.substr(domain.length).trim(),
                parts = rest.split('?'),
                path = parts[0]
            const params = (parts[1] || '').split('&').reduce((res, itm) => {
                const arr = itm.split('=')
                if (arr[0]) {
                    res[arr[0]] = typeof arr[1] !== 'undefined' ? arr[1] : true
                }
                return res
            }, {})
            //console.log('------', path, params)
            if ((path.substr(-3) === '.md' && sameDomain) || params.at === 'popup') {
                this.showPopup(href)
                return true
            }
        }
        return false
    }

    onLinkClick = (e) => {
        this.interceptLinkClick(e.target) && e.preventDefault()
    }


    showPopup(url) {
        this.setState({
            popupUrl: url,
            popupShow: true,
        })
    }

    _onPopupClickAway = (e) => {
        this.setState({
            popupShow: false,
        })
    }


    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
        document.body.addEventListener('click', this.onLinkClick)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
        document.body.removeEventListener('click', this.onLinkClick)
    }

    render() {
        const { errSelectors, specSelectors, getComponent, } = this.props

        const info = specSelectors.info(),
            title = info.get('title'),
            version = info.get('version'),
            description = info.get('description')

        const VersionPragmaFilter = getComponent("VersionPragmaFilter")
        const Markdown = getComponent("Markdown")
        const Errors = getComponent("errors", true)
        const FilterContainer = getComponent("FilterContainer", true)

        const TupuHeader = getComponent("TupuHeader", true)
        const Operations = getComponent("TOperations")
        const Popup = getComponent("TupuPopup", true)

        const isSwagger2 = specSelectors.isSwagger2()
        const isOAS3 = specSelectors.isOAS3()

        const isSpecEmpty = !specSelectors.specStr()
        const loadingStatus = specSelectors.loadingStatus()
        let loadingMessage = null

        if(loadingStatus === "loading") {
            loadingMessage = <div className="info">
                <div className="loading-container">
                    <div className="loading" />
                </div>
            </div>
        }

        if(loadingStatus === "failed") {
            loadingMessage = <div className="info">
                <div className="loading-container">
                    <h4 className="title">Failed to load API definition.</h4>
                    <Errors />
                </div>
            </div>
        }

        if (loadingStatus === "failedConfig") {
            const lastErr = errSelectors.lastError()
            const lastErrMsg = lastErr ? lastErr.get("message") : ""
            loadingMessage = <div className="info" style={{ maxWidth: "880px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                <div className="loading-container">
                    <h4 className="title">Failed to load remote configuration.</h4>
                    <p>{lastErrMsg}</p>
                </div>
            </div>
        }

        if(!loadingMessage && isSpecEmpty) {
            loadingMessage = <h4>No API definition provided.</h4>
        }

        if(loadingMessage) {
            return <div className="swagger-ui">
                <div className="loading-container">
                    {loadingMessage}
                </div>
            </div>
        }

        return (
            <div className="swagger-ui">
                <TupuHeader title={ title } version={ version } getComponent={ getComponent } overTop={this.state.overTop} />
                <VersionPragmaFilter isSwagger2={isSwagger2} isOAS3={isOAS3} alsoShow={<Errors  key="err1" />}>
                    <Errors key="err2" />
                    <div key="doc" className="doc-container">
                        <div className="info description">
                            <Markdown source={ description } />
                        </div>

                        <FilterContainer/>

                        <Operations getComponent={ getComponent } />
                    </div>
                </VersionPragmaFilter>
                <Popup url={this.state.popupUrl}
                       show={this.state.popupShow}
                       getComponent={getComponent}
                       onClickAway={this._onPopupClickAway} />
            </div>
        )
    }
}
