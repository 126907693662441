import React, { Component } from "react"
import PropTypes from "prop-types"
import Remarkable from "remarkable"
import Highlight from "highlight.js"
import DomPurify from "dompurify"

import 'highlight.js/styles/github.css'
import './popup.scss'


const isPlainText = (str) => /^[A-Z\s0-9!?.]+$/gi.test(str)


DomPurify.addHook("beforeSanitizeElements", (current) => {
    // Attach safe `rel` values to all elements that contain an `href`,
    // i.e. all anchors that are links.
    // We _could_ just look for elements that have a non-self target,
    // but applying it more broadly shouldn't hurt anything, and is safer.
    if (current.href) {
        current.setAttribute("rel", "noopener noreferrer")
    }
    return current
})

function sanitizer(str) {
    return DomPurify.sanitize(str, {
        ADD_ATTR: ["target"]
    })
}



export default class Popup extends Component {
    static propTypes = {
        show: PropTypes.bool,
        caption: PropTypes.string,
        url: PropTypes.string.isRequired,
        getComponent: PropTypes.func.isRequired,
        onClickAway: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            show: false,
            caption: props.caption || '',
            url: props.url,
            content: '',
        }

        this.md = new Remarkable({
            html: true,
            typographer: true,
            breaks: true,
            linkify: true,
            linkTarget: "_blank",
            highlight (str, lang) {
                if (lang && Highlight.getLanguage(lang)) {
                    try {
                        return Highlight.highlight(lang, str).value
                    }
                    catch (err) {}
                }

                try {
                    return Highlight.highlightAuto(str).value
                }
                catch (err) {}

                return '' // use external default escaping
            },
        })
        this.md.core.ruler.disable(["replacements", "smartquotes"])
    }

    UNSAFE_componentWillReceiveProps(props) {
        //console.log('show: ', props.show, '|', this.state.show)
        if (!props.show && this.state.show) {
            this.setState({
                show: false,
            })
        }
        else if (props.show) {
            const state = { show: true }
            if (props.url !== this.state.url) {
                Object.assign(state, {
                    caption: props.caption || '',
                    url: props.url,
                    content: '',
                })
                this.loadContent(props.url)
            }
            this.setState(state)
        }
    }

    loadContent(url) {
        if (!url) return
        fetch(url)
            .then(res => res.text())
            .then(source => {
                const content = isPlainText(source) ? source : sanitizer(this.md.render(source))
                this.setState({ content })
            })
    }

    onClickAway = (e) => {
        this.props.onClickAway()
    }

    onClickPanel = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation()
        }
        else {
            e.cancelBubble = true
        }
    }

    render() {
        //const { caption, url, } = this.state

        return (
            <div className={["popup-container", this.state.show ? "show" : ""].join(' ').trim()} onClick={this.onClickAway}>
                <div className="panel" onClick={this.onClickPanel}>
                    {
                        this.state.show && (
                            <div className="markdown" dangerouslySetInnerHTML={{ __html: this.state.content }} />
                        )
                    }
                </div>
            </div>
        )
    }
}
