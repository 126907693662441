import React, { Component } from "react"
import PropTypes from "prop-types"

import saveAs from "js-file-download"

import highlight from "highlight.js"



class Highlight extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        language: PropTypes.string,
        style: PropTypes.object,
        getConfigs: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.codeEl = React.createRef()
    }

    componentDidMount () {
        highlight.highlightBlock(this.codeEl.current)
    }

    componentDidUpdate () {
        highlight.initHighlighting.called = false
        highlight.highlightBlock(this.codeEl.current)
    }

    preventYScrollingBeyondElement = (e) => {
        const target = e.target

        let deltaY = e.nativeEvent.deltaY
        let contentHeight = target.scrollHeight
        let visibleHeight = target.offsetHeight
        let scrollTop = target.scrollTop

        const scrollOffset = visibleHeight + scrollTop

        const isElementScrollable = contentHeight > visibleHeight
        const isScrollingPastTop = scrollTop === 0 && deltaY < 0
        const isScrollingPastBottom = scrollOffset >= contentHeight && deltaY > 0

        if (isElementScrollable && (isScrollingPastTop || isScrollingPastBottom)) {
            const { getConfigs } = this.props
            const { disablePreventDefaultOnScroll = false } = getConfigs ? getConfigs() : {}
            if (!disablePreventDefaultOnScroll) e.preventDefault()
        }
    }

    render () {
        const { children, className, language, style, } = this.props

        return (
            <pre
                ref={this.codeEl}
                className={[className, "microlight"].join(' ').trim()}
                style={style}
                onWheel={this.preventYScrollingBeyondElement} >
                <code className={language} ref='code'>
                  {children}
                </code>
            </pre>
        )
    }
}




export default class HighlightCode extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        className: PropTypes.string,
        downloadable: PropTypes.bool,
        fileName: PropTypes.string,
        getConfigs: PropTypes.func.isRequired,
        // Added by Nickel #2019/12/06
        path: PropTypes.string,
        fn: PropTypes.object,
    }

    downloadText = () => {
        saveAs(this.props.value, this.props.fileName || "response.txt")
    }

    render () {
        // Added by Nickel with getConfigs #2019/05/21; with fn #2019/12/05
        let { value, className, downloadable, getConfigs, fn, path, } = this.props
        className = className || ""

        // Added by Nickel #2019/05/21
        const { getLangText } = getConfigs ? getConfigs() : { getLangText: t=>t }

        // Added by Nickel #2019/12/06
        if (fn && fn.pathResponseChanged && path) {
            fn.pathResponseChanged(value, path)
        }

        return (
            <div className="highlight-code">
                { !downloadable ? null :
                    <div className="download-contents" onClick={this.downloadText}>
                        { getLangText("Download") }
                    </div>
                }
                <Highlight
                    language="json"
                    className={className}
                    getConfigs={getConfigs}
                >
                  {value}
                </Highlight>
            </div>
        )
    }
}
