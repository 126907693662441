import React, { Component } from "react"
import PropTypes from "prop-types"

import './operations.scss'



export default class TOperations extends Component {
    static propTypes = {
        getComponent: PropTypes.func.isRequired,
    }

    render() {
        const {
            getComponent, } = this.props

        const Operations = getComponent('operations', true)

        return (
            <div className="tupu-operations">
                <Operations />
            </div>
        )
    }
}
